<!--

© 2020 – 2021 ProCSy JSC https://procsy.ru info@procsy.ru

© АО «ПроКСи», 2020 – 2021 info@procsy.ru

-->
<!-- Попап новой организации -->
<template>
  <CModal :show="isVisible">
    <div slot="header">
      <h5>{{ $t("organizations.creating") }}</h5>
    </div>

    <CInput
      :label="`${$t('organizations.organizationName')}*`"
      :invalidFeedback="validationMessages.required"
      :tooltipFeedback="false"
      :isValid="isValid.name"
      v-model.trim="newOrgData.name"
      @change="$v.newOrgData.name.$touch()"
      horizontal
    />

    <CInput
      label="MSPID*"
      horizontal
      :invalidFeedback="errorMessageMSPID"
      :tooltipFeedback="false"
      :isValid="isValid.mspid"
      @change="$v.newOrgData.mspid.$touch()"
      @input="changeMSPID"
      :value="newOrgData.mspid"
      ref="inputMSPID"
    />

    <CInput
      :label="$t('organizations.domain')"
      horizontal
      v-model.trim="newOrgData.domain"
      :invalidFeedback="validationMessages.required"
      :tooltipFeedback="false"
      :isValid="isValid.domain"
      @change="$v.newOrgData.domain.$touch()"
    />

    <!--TODO: Обновлять промежуточные при смене сертификата (или ссылку на root)-->
    <div role="group" class="form-group form-row mb-0">
      <label class="col-form-label col-sm-3">{{
        $t("organizations.certificate")
      }}</label>
      <div class="col-sm-9">
        <certificate v-if="isVisible"
          class="mb-3"
          :type-of="certTypes.cacerts.code"
          :required="true"
          @selected="selectedCertificate"
        ></certificate>
      </div>
    </div>

    <div
      role="group"
      class="form-group form-row"
      v-for="(item, i) in orgForm.intermediatecerts"
    >
      <CCol sm="3">
        {{ $t("organizations.between") }} {{ i + 1 }}
        <CButton @click="deleteIntermediateCert(i)" size="sm">
          <CIcon name="cil-trash" size="sm" />
        </CButton>
      </CCol>
      <CCol sm="9">
        <certificate v-if="isVisible"
          :type-of="certTypes.intermediatecerts.code"
          @selected="selectedCertificate"
        ></certificate>
      </CCol>
    </div>

    <CRow class="mb-4">
      <CCol sm="3"> </CCol>
      <CCol sm="9">
        <CLink @click.prevent.default="addIntermediateCert"
          >{{ $t("organizations.intermediateAdd") }}
        </CLink>
      </CCol>
    </CRow>

    <div role="group" class="form-group form-row">
      <label class="col-form-label col-sm-3">{{
        $t("organizations.sertUcCa")
      }}</label>
      <div class="col-sm-9">
        <certificate v-if="isVisible"
          :type-of="certTypes.tlscacerts.code"
          :required="true"
          @selected="selectedCertificate"
        ></certificate>
      </div>
    </div>

    <div
      role="group"
      class="form-group form-row"
      v-for="(item, i) in orgForm.intermediatetlscerts"
      :key="i"
    >
      <CCol sm="3">
        {{ $t("organizations.tls") }} {{ i + 1 }}
        <CButton @click="deleteIntermediateTLSCert(i)" size="sm">
          <CIcon name="cil-trash" size="sm" />
        </CButton>
      </CCol>
      <CCol sm="9">
        <certificate v-if="isVisible"
          :type-of="certTypes.tlsintermediatecerts.code"
          @selected="selectedCertificate"
        ></certificate>
      </CCol>
    </div>

    <CRow class="mb-4">
      <CCol sm="3"> </CCol>
      <CCol sm="9">
        <CLink @click.prevent.default="addIntermediateTLSCert"
          >{{ $t("organizations.interCert") }}
        </CLink>
      </CCol>
    </CRow>

    <div slot="footer" class="footer-buttons">
      <CButton type="button" class="btn btn-secondary" @click="closeModal">
        {{ $t("common.cancel") }}
      </CButton>
      <CButton
        type="button"
        class="btn btn-primary"
        @click="createOrganization"
        :disabled="!canCreate"
      >
        {{ $t("common.create") }}
      </CButton>
    </div>
  </CModal>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { mapGetters } from "vuex";

import utilMessages from "@/utils/messages";
import { isValidMSPID } from "@/utils/validators";
import Certificate from "../certificates/Certificate.vue";
import { CertificateTypes } from "../certificates/constants";
import { validationMessages } from "../messages";

export default {
  name: "OrganizationForm",
  components: {
    Certificate,
  },
  props: {
    isVisible: {
      type: Boolean,
    },
  },
  data() {
    return {
      certTypes: CertificateTypes,
      validationMessages: validationMessages,
      isShow: false,
      orgForm: {
        intermediatecerts: [],
        intermediatetlscerts: [],
      },
      newOrgData: {},
    };
  },
  validations: {
    newOrgData: {
      mspid: {
        required,
        isValidMSPID,
      },
      domain: {
        required,
      },
      msp: {
        cacerts: {
          required,
        },
        tlscacerts: {
          required,
        },
      },
      name: {
        required,
      },
    },
  },
  async mounted() {
    this.newOrgData = this.initEmptyOrganization();
  },
  computed: {
    ...mapGetters(["getCertificate"]),
    isValid() {
      let mspid, domain, name;

      if (this.$v.newOrgData.mspid.$dirty) {
        mspid = !this.$v.newOrgData.mspid.$invalid;
      }

      if (this.$v.newOrgData.domain.$dirty) {
        domain = !this.$v.newOrgData.domain.$invalid;
      }

      if (this.$v.newOrgData.name.$dirty) {
        name = !this.$v.newOrgData.name.$invalid;
      }

      return {
        mspid,
        domain,
        name,
      };
    },
    errorMessageMSPID() {
      if (!this.$v.newOrgData.mspid.required) {
        return this.validationMessages.required;
      }

      if (!this.$v.newOrgData.mspid.isValidMSPID) {
        return this.validationMessages.MSPID;
      }

      return "";
    },
    canCreate() {
      return !this.$v.newOrgData.$invalid;
    },
  },
  methods: {
    showNewOrgModal() {
      this.isShow = true;
    },
    initEmptyOrganization() {
      let organization = {
        mspid: null,
        domain: null,
        msp: {},
        name: "",
      };

      organization.msp[this.certTypes.cacerts.code] = [];
      organization.msp[this.certTypes.tlscacerts.code] = [];
      organization.msp[this.certTypes.intermediatecerts.code] = [];
      organization.msp[this.certTypes.tlsintermediatecerts.code] = [];
      organization.msp[this.certTypes.admincerts.code] = [];
      organization.msp[this.certTypes.signcerts.code] = [];

      return organization;
    },
    async createOrganization() {
      try {
        const org = await this.$store.dispatch(
          "createOrganization",
          this.newOrgData
        );

        this.closeModal();
        this.$emit("createdOrganization", org);
        this.$toast.success(this.$i18n.t("organizations.created"));
      } catch (err) {
        this.$toast.error(utilMessages.errMessage(err));
      }
    },
    selectedCertificate(cert, prevPem) {
      let changedCertId;

      const certData = {
        pem: cert.pem,
        type: cert.type,
      };

      if (this.newOrgData.msp[cert.type]) {
        // remove previous cert
        changedCertId = this.newOrgData.msp[cert.type].findIndex(
          (cert) => cert.pem === prevPem
        );
        if (changedCertId !== -1)
          this.newOrgData.msp[cert.type].splice(changedCertId, 1);

        // add new cert
        this.newOrgData.msp[cert.type].push(certData);
      }
    },
    closeModal() {
      this.newOrgData = this.initEmptyOrganization();
      this.orgForm = {
        intermediatecerts: [],
        intermediatetlscerts: [],
      };
      this.$v.$reset();
      this.$emit("close");
    },
    addIntermediateCert() {
      this.orgForm.intermediatecerts.push({});
    },
    deleteIntermediateCert(i) {
      this.orgForm.intermediatecerts.splice(i, 1);
    },
    addIntermediateTLSCert() {
      this.orgForm.intermediatetlscerts.push({});
    },
    deleteIntermediateTLSCert(i) {
      this.orgForm.intermediatetlscerts.splice(i, 1);
    },
    changeMSPID(val, inputEvent) {
      if (val === this.newOrgData.mspid) {
        return;
      }

      if (isValidMSPID(val) || !val) {
        this.$set(this.newOrgData, "mspid", val);
      } else {
        this.$refs.inputMSPID.$set(
          this.$refs.inputMSPID.$data,
          "state",
          this.newOrgData.mspid
        );
      }
    },
  },
};
</script>

<style scoped>
.footer-buttons button {
  margin-left: 5px;
}

.display-inline {
  display: inline;
}
</style>
