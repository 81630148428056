<!--

© 2020 – 2021 ProCSy JSC https://procsy.ru info@procsy.ru

© АО «ПроКСи», 2020 – 2021 info@procsy.ru

-->

<template>
  <div>
    <label :class="addLabelClasses">{{ label }}</label
    ><br />
    <CCard
      class="mb-2"
      v-for="(item, i) in elementList"
      :accentColor="accordion === i ? 'primary' : ''"
      :key="i"
    >
      <CCardHeader
        class="border-bottom-0 collapse_item_header"
        @click="accordion = accordion === i ? false : i"
      >
        <strong>{{ getOrgName(item) }}</strong>

        <div class="card-header-actions">
          <CButton
            v-if="elementList[i].id !== getUser.org_id"
            @click.stop.prevent="deleteItem(i)"
            size="sm"
            class="p-0 m-0 mr-4"
          >
            <CIcon name="cil-trash" size="sm" />
          </CButton>
          <CButton size="sm" class="p-0 m-0">
            <fa-icon icon="angle-up" size="lg" v-if="accordion === i" />
            <CIcon name="cil-pencil" size="sm" v-if="accordion !== i" />
          </CButton>
        </div>
      </CCardHeader>

      <CCollapse :show="accordion === i">
        <CCardBody class="border-top">
          <NetworkOrgForm
            :org="elementList[i]"
            :ordererOrganization="objectType == 'organization_orderer'"
            @createdOrderer="addOrderer"
            @deletedOrderer="removeOrderer"
            @selectedOrganization="selectedOrganization(i, $event)"
          />
        </CCardBody>
      </CCollapse>
    </CCard>

    <CButton color="dark" variant="outline" @click="handleAddButton">
      <CIcon name="cil-plus" /> {{ addButtonLabel }}
    </CButton>

    <AddOrganizationModal
      :is-opened="showAddOrganizationModal"
      @close="closeAddOrganizationModal"
      @organizationSelected="addNewParticipant"
    ></AddOrganizationModal>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

import AddOrganizationModal from "../components/AddOrganizationModal.vue";
import NetworkOrgForm from "../components/NetworkOrgForm.vue";

export default {
  name: "FormObjectsCollapse",

  data() {
    return {
      accordion: false,
      showAddOrganizationModal: false,
    };
  },

  components: {
    NetworkOrgForm,
    AddOrganizationModal,
  },

  props: {
    label: String,
    addLabelClasses: String,
    addButtonLabel: String,

    ordererOrganizations: Array,
    orderers: Array,
    consortium: Array,

    itemNameAttr: {
      type: Array,
      default: ["name"],
    },
    itemNewName: String,
    objectType: String,
  },

  computed: {
    ...mapGetters(["getUser"]),
    elementList() {
      switch (this.objectType) {
        case "organization_orderer":
          return this.ordererOrganizations;
        case "organization_peer":
          return this.consortium;
        default:
          return {};
      }
    },
  },

  methods: {
    ...mapMutations([
      "updateDraftOrdererOrgs",
      "updateDraftConsortium",
      "addOrderer",
      "removeOrderer",
    ]),
    addItem() {
      this.elementList.push({ id: "" });
      this.accordion = this.elementList.length - 1;
    },
    deleteItem(i) {
      this.elementList.splice(i, 1);
      this.updateOrganizationList();
    },
    selectedOrganization(i, organization) {
      Object.assign(this.elementList[i], organization);
      this.updateOrganizationList();
    },
    updateOrganizationList() {
      if (this.objectType === "organization_orderer")
        this.$store.dispatch("updateDraftOrdererOrgs", this.elementList);
      else this.$store.dispatch("updateDraftConsortium", this.elementList);
    },
    getOrgName(org) {
      for (let i = 0; i < this.itemNameAttr.length; i++) {
        const propName = this.itemNameAttr[i];

        if (org[propName]) {
          return org[propName];
        }
      }
    },
    handleAddButton(event) {
      event.preventDefault();
      event.stopPropagation();

      this.showAddOrganizationModal = true;
    },
    closeAddOrganizationModal() {
      this.showAddOrganizationModal = false;
    },
    addNewParticipant(organization) {
      this.elementList.push(organization);
      this.updateOrganizationList();
    }
  },
};
</script>

<style>
.collapse_item_header {
  cursor: pointer;
}
.collapse_item_header.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) calc(0.25rem - 1px)
    calc(0.25rem - 1px);
}
</style>
