/*

© 2020 – 2021 ProCSy JSC https://procsy.ru info@procsy.ru

© АО «ПроКСи», 2020 – 2021 info@procsy.ru

*/



export const EndpointTypes = {
  orderer: {code: 'orderer'},
  peer: {code: 'peer'},
  admin: {code: 'admin'},
  client: {code: 'client'},
  fabric_admin_tools: {code: 'fabric-admin-tools'},
}

export const EndpointStatuses = {
  attached: {code: 'attached'},
  detached: {code: 'detached'},
}
