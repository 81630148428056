<!--

© 2020 – 2021 ProCSy JSC https://procsy.ru info@procsy.ru

© АО «ПроКСи», 2020 – 2021 info@procsy.ru

-->


<template>
  <div>
    <!-- Попап нового ордерера -->
    <template v-if='modalShowed'>
      <CModal :title="$t('orderer.addingAnOrderer')" :show.sync="modalShowed">

        <CInput v-if="org" v-model="newOrderData.hostname" horizontal :label="`${$t('orderer.dnsName')}*`"
          :placeholder="$t('orderer.dnsName')" class="mb-2" :isValid="isValid.hostname" :tooltipFeedback=false
          :invalidFeedback="validationMessages.required" @change="$v.newOrderData.hostname.$touch()">
          <template #append-content>
            <span> {{ org.domain || 'empty.com' }}</span>
          </template>
        </CInput>

        <CInput v-model="newOrderData.port" horizontal :label="`${$t('orderer.port')}*`"
          :placeholder="$t('orderer.enterPort')" class="mb-2" :isValid="isValid.port" :tooltipFeedback=false
          :invalidFeedback="validationMessages.required" @change="$v.newOrderData.port.$touch()"></CInput>
        <div role="group" class="form-group form-row mb-0">
          <label class="col-form-label col-sm-3"> {{ $t('orderer.tlsCertificate') }}* </label>
          <div class="col-sm-9">
            <certificate :placeholder="$t('orderrer.specifyTlsCertificate')" :org-id="orgId"
              :type-of="certTypes.tlscerts.code" :required="true" @selected="selectedCertificate"
              @created="createdCertificate"></certificate>
          </div>
        </div>

        <div slot="footer" class="footer-buttons">
          <CButton type="button" class="btn btn-secondary" @click="closeModal"> {{ $t('common.cancel') }} </CButton>
          <CButton type="button" class="btn btn-primary" @click="createOrderer" :disabled="!canCreate"> {{
              $t('common.create')
          }} </CButton>
        </div>
      </CModal>
    </template>

    <template>
      <div role="group" class="form-group form-row" v-for="(item, i) in orgOrderers" :key="i">
        <CCol sm="3">
          {{ $t('orderer.orderer') }} {{ (i + 1) }}
          <CButton @click="deleteOrderer(i)" size="sm">
            <CIcon name="cil-trash" size="sm" />
          </CButton>
        </CCol>
        <CCol sm="9">
          <span class="mb-1">
            {{ $t('orderer.dnsName') }}:
            <b>{{ orgOrderers[i].hostname }}</b>
          </span>
          <br>
          <span>
            {{ $t('orderer.tlsCertificate') }}:
            <certificate :org-id="orgId" :type-of="certTypes.admincerts.code" :text-if-is="true"
              :cert-id="orgOrderers[i].tls_cert_id"></certificate>
          </span>
        </CCol>
      </div>
      <div class="form-row">
        <label class="col-sm-3">
          <template v-if="orgOrderers && !orgOrderers.length">
            {{ $t('orderer.orderer') }}
          </template>
        </label>
        <div class="col-sm-9">
          <CLink @click.prevent.default="showModal">{{ $t('orderer.addOrderer') }}</CLink>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'

import utilMessages from '../../../../utils/messages'
import messages from '../../messages'
import Certificate from '../../certificates/Certificate.vue'
import { CertificateTypes } from '../../certificates/constants'

import { EndpointStatuses, EndpointTypes } from './constants'

export default {
  name: 'NewOrderer',
  components: {
    Certificate
  },

  props: {
    orgId: {
      type: String,
      required: true
    },
  },

  data() {
    return {
      validationMessages: messages.validation,
      certTypes: CertificateTypes,
      endpointTypes: EndpointTypes,
      endpointStatuses: EndpointStatuses,

      org: null,
      newOrderData: {
        hostname: null,
        port: '7050',
        tlscert: null,
      },
      modalShowed: false,
      newAdminCerts: [],
      orderers: [],
    }
  },

  validations: {
    newOrderData: {
      hostname: {
        required
      },
      port: {
        required
      },
      tlscert: {
        required
      }
    }
  },

  mounted() {
    this.$v.newOrderData.port.$touch()
    this.loadOrganization()

  },

  computed: {
    isValid() {
      let hostname, port;
      if (this.$v.newOrderData.hostname.$dirty)
        hostname = !this.$v.newOrderData.hostname.$invalid

      if (this.$v.newOrderData.port.$dirty)
        port = !this.$v.newOrderData.port.$invalid

      return {
        hostname: hostname,
        port: port,
      }
    },

    canCreate() {
      return !this.$v.newOrderData.$invalid
    },

    orgOrderers() {
      let orderers = []
      if (this.$store.getters.selectedDraftNetwork)
        orderers = this.$store.getters.selectedDraftNetwork.orderers.filter(orderer => orderer.org_id === this.orgId)

      return orderers
    }
  },

  methods: {
    async createOrderer() {
      if (!this.newOrderData.tlscert || !this.newOrderData.tlscert.id) {
        this.$toast.error(this.$i18n.t('orderer.certificateNotSpecified'));
        return;
      }

      const draftNetwork = this.$store.getters.selectedDraftNetwork

      let ordererData = {
        hostname: [this.newOrderData.hostname, this.org.domain].join('.'),
        port: this.newOrderData.port,
        org_id: this.orgId,
        tls_cert_id: this.newOrderData.tlscert.id,
        type: this.endpointTypes.orderer.code,
        status: this.endpointStatuses.detached.code,
        blockchain_net_id: draftNetwork.id,
        version: draftNetwork.version,
      }

      try {
        const orderer = await this.$store.dispatch('createEndpoint', ordererData);
        this.$emit('created', orderer);
        this.closeModal();
        this.$toast.success(this.$i18n.t('orderer.ordererCreated'));
      } catch (err) {
        this.$toast.error(utilMessages.errMessage(err))
      }
    },

    async deleteOrderer(i) {
      const ordererId = this.orgOrderers[i].id
      try {
        await this.$store.dispatch('deleteEndpoint', ordererId)
        this.$store.dispatch('deleteOrderer', ordererId)
        this.$toast.success(this.$i18n.t('orderer.ordererDeleted'))
        this.$emit('deleted', ordererId)

      } catch (err) {
        this.$toast.error(utilMessages.errMessage(err))

      }
    },

    closeModal() {
      this.modalShowed = false
      this.newOrderData = {
        hostname: null,
        port: '7050',
        tlscert: null,
      }
      this.$v.$reset()
      this.$v.newOrderData.port.$touch()
    },

    showModal() {
      this.modalShowed = true
    },

    selectedCertificate(cert) {
      console.log('Selected certificate: ', cert)
      this.newOrderData.tlscert = cert
    },

    createdCertificate(cert) {
      console.log('Created certificate: ', cert)
      this.newOrderData.tlscert = cert
    },

    async loadOrganization() {
      this.org = this.$store.getters.getOrganization(this.orgId)
      if (!this.org) {
        try {
          let res = await this.$store.dispatch('fetchOrganizations')
          this.org = this.$store.getters.getOrganization(this.orgId)
          console.log('res: ', res)
        }
        catch (err) {
          this.$toast.error(utilMessages.errMessage(err))

        }
      }
    }
  },

  watch: {},
};
</script>

<style scoped>
.footer-buttons button {
  margin-left: 5px;
}
</style>
