<!--

© 2020 – 2021 ProCSy JSC https://procsy.ru info@procsy.ru

© АО «ПроКСи», 2020 – 2021 info@procsy.ru

-->

<template>
  <div>
    <div class="form-item">
      <span class="form-item__label">MSP ID:</span>
      <span class="form-item__value">{{ org.mspid }}</span>
    </div>

    <!-- Организация уже выбрана-->
    <template v-if="selectedOrganizationId">
      <CRow class="mb-3" v-if="!CACertsExists">
        <CCol sm="3"></CCol>
        <CCol sm="9">
          <CAlert color="warning">{{ $t("network.sertIsAbsent") }}</CAlert>
        </CCol>
      </CRow>
      <template v-else>
        <CRow class="mb-3">
          <CCol sm="3">
            {{ $t("network.sertUcCa") }}
          </CCol>
          <CCol sm="9">
            <certificate
              :org-id="selectedOrganizationId"
              :type-of="certTypes.cacerts.code"
              :cert-id="selectedOrg.msp[certTypes.cacerts.code][0]"
              :text-if-is="true"
            ></certificate>
          </CCol>
        </CRow>
        <CRow class="mb-3">
          <CCol sm="3">
            {{ $t("network.sertUcCaTls") }}
          </CCol>
          <CCol sm="9">
            <certificate
              :org-id="selectedOrganizationId"
              :type-of="certTypes.tlscacerts.code"
              :cert-id="selectedOrg.msp[certTypes.tlscacerts.code][0]"
              :text-if-is="true"
            ></certificate>
          </CCol>
        </CRow>
      </template>

      <!-- Список ордереров -->
      <template v-if="ordererOrganization">
        <OrdererList
          v-if="selectedOrganizationId"
          :org-id="selectedOrganizationId"
          @created="createdOrderer"
          @deleted="deletedOrderer"
        ></OrdererList>
      </template>
    </template>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import utilMessages from "../../../utils/messages";
import Certificate from "../certificates/Certificate.vue";
import { CertificateTypes } from "../certificates/constants";
import { NetworkOrgTypes } from "../networks/constants";
import OrdererList from "../organizations/endpoints/OrdererList.vue";

export default {
  name: "NetworkOrgForm",
  components: {
    OrdererList,
    Certificate,
  },

  props: {
    org: {
      type: Object,
      required: false,
    },
    ordererOrganization: Boolean,
  },

  data() {
    return {
      certTypes: CertificateTypes,
      showAddAdminCert: false,
      selectedOrganizationId: null,
      organizations: [],
      certificates: [],
      adminCertificates: [],
    };
  },

  validations: {},

  async mounted() {
    this.selectedOrganizationId = this.org?.id;
  },

  watch: {
    async selectedOrganizationId(newVal) {
      if (newVal === this.org.id) {
        return;
      }

      this.$emit(
        "selectedOrganization",
        this.getOrganization(this.selectedOrganizationId)
      );
      await this.loadAdminCertificates();
    },
  },

  computed: {
    ...mapGetters([
      "allOrganizations",
      "getOrganization",
      "getOrdererOrgAdminCerts",
      "getConsortOrgAdminCerts",
      "allCertificates",
      "getDraftNetworkOrgIds",
      "getUser",
    ]),

    selectedOrg() {
      if (this.org && this.selectedOrganizationId == this.org.id) {
        return this.org;
      }

      return this.allOrganizations.find(
        (org) => org.id === this.selectedOrganizationId
      );
    },

    orgSelectValues() {
      let orgs = [];

      const draftNetworkOrgIds = this.getDraftNetworkOrgIds;

      this.allOrganizations.forEach((item) => {
        if (
          item.id === this.selectedOrganizationId ||
          !draftNetworkOrgIds ||
          !draftNetworkOrgIds.find((orgId) => orgId === item.id)
        ) {
          orgs.push({
            value: item.id,
            label: item.name,
          });
        }
      });

      return orgs;
    },

    CACertsExists() {
      if (this.selectedOrganizationId) {
        const org = this.selectedOrg;
        return Boolean(
          org &&
            org.msp &&
            org.msp[this.certTypes.cacerts.code] &&
            org.msp[this.certTypes.tlscacerts.code] &&
            org.msp[this.certTypes.cacerts.code].length &&
            org.msp[this.certTypes.tlscacerts.code].length
        );
      }
      return false;
    },
  },

  methods: {
    async loadOrganizations() {
      try {
        await this.$store.dispatch("fetchOrganizations", {
          view: "blockchain",
        });
        if (this.id) {
          this.selectedOrganizationId = this.id;
        }
      } catch (err) {
        this.$toast.error(utilMessages.errMessage(err));
      }
    },

    async loadAdminCertificates() {
      let certGetter;
      if (this.ordererOrganization)
        certGetter = this.$store.getters.getOrdererOrgAdminCerts;
      else certGetter = this.$store.getters.getConsortOrgAdminCerts;

      this.adminCertificates = certGetter(this.selectedOrganizationId);
    },

    createdOrganization(org) {
      this.selectedOrganizationId = org.id;
    },

    addOrderer() {
      this.orderers.push({});
    },
    deleteOrderer(i) {
      this.orderers.splice(i, 1);
    },

    addAdmincert() {
      this.adminCertificates.push(null);
    },

    selectedAdminCertificate(cert) {
      let type;
      if (this.ordererOrganization) {
        type = NetworkOrgTypes.orderer_organizations;
      } else {
        type = NetworkOrgTypes.consortium;
      }

      try {
        this.$store.dispatch("addOrgAdminCert", {
          orgId: this.selectedOrganizationId,
          certId: cert.id,
          type,
        });
      } catch (err) {
        this.$toast.error(err.message);
      }

      this.loadAdminCertificates();

      this.showAddAdminCert = false;
    },
    async deleteAdmincert(certId) {
      let type;

      try {
        await this.$store.dispatch("deleteCertificate", {
          certId,
          params: { org_id: this.selectedOrganizationId },
        });
        this.$toast.success(this.$i18n.t("network.sertRemoved"));
        await this.loadOrganizations();

        if (this.ordererOrganization) {
          type = NetworkOrgTypes.orderer_organizations;
        } else {
          type = NetworkOrgTypes.consortium;
        }
        this.$store.dispatch("removeOrgAdminCert", {
          orgId: this.selectedOrganizationId,
          certId,
          type,
        });
      } catch (err) {
        this.$toast.error(utilMessages.errMessage(err));
      }
    },

    createdOrderer(orderer) {
      this.$emit("createdOrderer", orderer);
    },

    deletedOrderer(ordererId) {
      this.$emit("deletedOrderer", ordererId);
    },
  },
};
</script>

<style scoped>
.footer-buttons button {
  margin-left: 5px;
}

.form-item {
  display: flex;
  flex-direction: row;
  margin-bottom: 12px;
}

.form-item__label {
  font-weight: 700;
  margin-right: 10px;
}
</style>
