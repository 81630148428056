<template>
  <CModal
    alignment="center"
    :show="isOpened"
    :closeOnBackdrop="true"
    @close="closeDialog()"
  >

  <div slot="header">
      <h5>{{ $t('network.addNewParticipant') }}</h5>
    </div>

    <div class="content w-100">
      <div v-if="organizations.length > 0" class="organization-selector">
        <CSelect
          :options="organizations"
          :placeholder="$t('network.chooseOrg')"
          :value.sync="selectedOrganizationId"
          class="mb-1 w-100"
          :horizontal="{ input: 'w-100' }"
        />
        <span>{{ $t("common.or") }}</span>
      </div>

      <span v-if="organizations.length === 0">{{ $t('network.noOrganizationAvailable') }}</span>

      <CLink @click.prevent.default="showNewOrgModal">{{
        $t("organizations.addNew")
      }}</CLink>

      <OrganizationForm
        :button-text="$t('network.createNewOrg')"
        :is-visible="isCreateOrganizationModalVisible"
        @createdOrganization="createNewOrganization"
        @close="closeCreateOrganizationModal"
      />
    </div>

    <div slot="footer" class="footer-buttons">
      <CButton
        type="button"
        class="btn btn-secondary mr-2"
        @click="closeDialog"
      >
        {{ $t("common.cancel") }}
      </CButton>
      <CButton
        type="button"
        class="btn btn-primary"
        @click="confirmOrganization"
        :disabled="!selectedOrganizationId"
      >
        {{ $t("common.continue") }}
      </CButton>
    </div>
  </CModal>
</template>

<script>
import { CLink, CSelect } from "@coreui/vue";
import { mapGetters } from "vuex";

import OrganizationForm from "../organizations/OrganizationForm";

export default {
  name: "AddOrganizationModal",
  components: { CLink, CSelect, OrganizationForm },
  props: {
    isOpened: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedOrganizationId: null,
      isCreateOrganizationModalVisible: false,
    };
  },
  computed: {
    ...mapGetters(["allOrganizations", "getDraftNetworkOrgIds"]),
    organizations() {
      let orgs = [];

      const draftNetworkOrgIds = this.getDraftNetworkOrgIds;

      this.allOrganizations.forEach((item) => {
        if (
          item.id === this.selectedOrganizationId ||
          !draftNetworkOrgIds ||
          !draftNetworkOrgIds.find((orgId) => orgId === item.id)
        ) {
          orgs.push({
            value: item.id,
            label: item.name || item.mspid,
          });
        }
      });

      console.log(orgs);

      return orgs;
    },
  },
  methods: {
    closeDialog() {
      this.selectedOrganizationId = null;
      this.$emit("close");
    },
    closeCreateOrganizationModal() {
      this.isCreateOrganizationModalVisible = false;
    },
    confirmOrganization() {
      if (!this.selectedOrganizationId) {
        return;
      }

      const organization = this.allOrganizations.find(
        (item) => item.id === this.selectedOrganizationId
      );

      this.$emit("organizationSelected", organization);
      this.closeDialog();
    },
    createNewOrganization(org) {
      try {
        this.$emit("organizationSelected", org);
        this.closeDialog();
      } finally {
        this.isCreateOrganizationModalVisible = false;
      }
    },
    showNewOrgModal() {
      this.isCreateOrganizationModalVisible = true;
    },
  },
};
</script>

<style>
.content {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  align-items: center;
}

.organization-selector {
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
